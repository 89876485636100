<style lang="sass" scoped>
.container-login
  font-size: 12px
</style>
<template lang="pug">
div.container-login
  .container
    .row.justify-content-center.align-items-center.async(style='height: 100vh' :class='{done:done}')
      .col-xl-5.col-lg-6.col-md-7
        h3.text-center
          span Welcome to
          strong  Noitaler 👋
        h6.text-muted.text-center 관리자 로그인
        .mb-4
        form.form(@submit.prevent='')
          .form-group
            label.d-block: strong 이메일 주소
            input.form-control(type='text' name='f1' autocomplete='off' v-model='form.email' required autofocus)
          .form-group
            label.d-block: strong 비밀번호
            input.form-control(type='password' name='f2' v-model='form.password' required)

          .text-right.mb-3
            a(href='#').text-primary 비밀번호 찾기

          .mb-3
            button.btn.btn-primary.py-4.btn-block(type='submit') 로그인

          .text-center
            span.text-muted.mr-1 무료로 시작해보세요.
            a(href='#').text-primary 회원가입

</template>

<script>


export default {
  name: 'index',
  components: {

  },
  computed: {

  },
  mounted() {
    // this.$store.dispatch('session')
    this.load()
  },
  data() {
    return {
      done: false,
      form: {},
    }
  },
  methods: {
    async load() {
      try {
        // const r = await this.$http.get('/v1')
        // console.log('ping:', r?.data)

        setTimeout(() => {
          this.done = true
        }, 300);
      } catch (error) {
        console.log({error})
      }
    },
  },
}
</script>
